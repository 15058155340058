import * as React from "react";
import {Redirect} from "react-router-dom";


export function Auth({children, profile}) {

    if (profile) {
        return React.Children.map(children, child => {
            return React.cloneElement(child, {profile})
        })
    } else {
        return <Redirect to={"/login"}/>
    }

}
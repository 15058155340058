import {StromnoClient} from "../api/StromnoClient";
import * as React from "react";
import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";

export const FeatureDescription = ({text, id, color}) => {
    const [show, setShow] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [content, setContent] = useState("")
    const [title, setTitle] = useState("Loading...")

    useEffect(() => {
        StromnoClient.getFeatureDescription(id).then(([{title, content},]) => {
            setLoaded(true);
            setContent(content);
            setTitle(title);
        })
    }, [])


    let modalContent = content;
    if (show && !loaded) {
        modalContent = <div>Loading...</div>
    }

    return <>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href={"#"} style={{color: color ? color : "#7F8F8F"}} onClick={() => setShow(true)}>{text}</a>

        <Modal
            size={"lg"}
            show={show}
            onHide={() => setShow(false)}
        >
            <Modal.Header closeButton>
                <h2>{title}</h2>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{__html: modalContent}}>
                </div>
            </Modal.Body>
        </Modal>
    </>


}

export const LoginAppleWatch = () => <FeatureDescription text={"ⓘ How to login on watch?"}
                                                         id={"c5f11769-4342-4d6e-ae5d-1cd95291626f"}/>;
export const AddHeartRateObs = () => <FeatureDescription text={"ⓘ How to add real heart rate in OBS?"}
                                                         id={"1f5980f0-f8ad-49dd-ab6f-783088957311"}/>;
export const LearnMoreConnetToPulsoid = () => <FeatureDescription text={"ⓘ"} color={"#11a2b1"}
                                                                  id={"640bc83d-9022-46f6-b85c-1bc2c6ecfc0b"}/>
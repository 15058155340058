import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import {ProcessingOverlay, PulsoidColors, WhiteText} from "../../common/system";
import {useHistory} from "react-router";
import {GetToken, StromnoClient} from "../../api/StromnoClient";
import * as Sentry from "@sentry/react";

export function ConnectingPulsoid() {
    const history = useHistory();
    const [error, setError] = useState(null);


    useEffect(() => {
        async function exchange() {
            let code = getQueryStringValue("code")
            if(code === ""){
                Sentry.setTag("stromno-token", GetToken());
                Sentry.captureMessage(`Error exchanging ${code} with error ${getQueryStringValue("error")} and ${getQueryStringValue("error_description")}`);
                setError(<Row>
                    <h3>
                        Something went wrong while connecting to Pulsoid.
                    </h3>
                    <br/>
                </Row>)
                return;
            }
            let [response, getPulsoidTokenError] = await StromnoClient.getPulsoidToken(code);
            if (getPulsoidTokenError) {
                Sentry.setTag("stromno-token", GetToken());
                Sentry.captureMessage(`Error exchanging ${code} with error ${JSON.stringify(getPulsoidTokenError)}`);
                setError(<Row>
                    <h3>
                        Something went wrong while exchanging code.
                    </h3>
                    <br/>
                </Row>)
                return;
            }
            let [_, joinStreamsError] = await StromnoClient.joinStreams(response.token)
            if (joinStreamsError) {
                Sentry.setTag("stromno-token", GetToken());
                Sentry.captureMessage(`Error joining streams error ${JSON.stringify(joinStreamsError)} with token ${response.token}`);
                setError(<Row>
                    <h3>
                        Something went wrong while joining streams.
                    </h3>


                </Row>)
                return;
            }
            history.push("/dashboard")
        }

        exchange();

    }, []);


    return <Row className="justify-content-md-center ml-2" style={{alignItems: "center", height: '70vh'}}>
        <ProcessingOverlay processing={!error}>
            {error}
            <Row className={"justify-content-md-center"}>
                <a href={"/dashboard"} style={PulsoidColors}>Go to Dashboard</a>
            </Row>
            <Row className={"justify-content-md-center"}>
                <h6><WhiteText>Contact us via <a style={PulsoidColors} href={"https://bit.ly/3nOkWcK"}>Discord</a> or stromno.app@gmail.com</WhiteText></h6>
            </Row>
        </ProcessingOverlay>
    </Row>

}

function getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

import * as React from "react";
import {StromnoClient} from "../../api/StromnoClient";
import Websocket from "react-websocket";
import {Col} from "react-bootstrap";
import apple_watch from "../../thumbnail-apple-watch-series-5_2x.png";

export class Status extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {status: "closed", timestamp: 0}
    }

    handleData(data) {
        let result = JSON.parse(data);
        this.setState({value: result.data.heartRate, timestamp: result.timestamp});
    }


    onlineTask = () => {
        if ((new Date()).getTime() - this.state.timestamp > 20_000) {
            let statusBefore = this.state.status
            this.setState({status: "closed"})
            if (statusBefore === "opened") {
                this.generateLoginCode()
            }
        } else {

            this.setState({status: "opened"})

        }
    };

    componentDidMount() {
        let onlineTaskIntervalId = setInterval(this.onlineTask, 1000);
        let generateLoginCodeTaskIntervalId = setInterval(this.generateLoginCode, 180000)
        this.generateLoginCode();
        this.setState({onlineTaskIntervalId, generateLoginCodeTaskIntervalId});
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.onlineTaskIntervalId);
        clearInterval(this.state.generateLoginCodeTaskIntervalId);
    }

    isOnline = () => this.state.status === "opened"
    generateLoginCode = () => {
        if (this.state.status === "closed") {
            StromnoClient.generateLoginCode().then(([loginCode,]) => {
                this.setState({loginCode: loginCode.code.split('').join(' ')})
            })
        }
    }

    render() {
        return (<>
            <Websocket reconnectIntervalInMilliSeconds={3000}
                       url={this.props.listen}
                       onMessage={this.handleData.bind(this)}/>

            <Col xs={"auto"} style={{
                backgroundImage: 'url("' + apple_watch + '")',
                height: 257,
                width: 153,
            }}>
                {this.isOnline() ?
                    <>
                        <div style={{
                            position: "absolute", bottom: 237 / 2 + 36, left: 153 / 2 - 14,
                            height: 25,
                            width: 25,
                            backgroundColor: "red",
                            borderRadius: "50%",
                            display: "inline-block"
                        }} className={"status_blink"}/>
                        <div
                            style={{position: "absolute", bottom: 237 / 2 + 10, left: 60 - 14, color: "white",}}>ONLINE
                        </div>
                        <div style={{
                            position: "absolute",
                            bottom: 237 / 2 - 20,
                            left: 50 - 14,
                            color: "white",
                            backgroundColor: "black",
                            borderRadius: 3,
                            wordSpacing: 1
                        }}>&nbsp;BPM : {this.state.value}&nbsp;</div>
                    </> :
                    <>
                        <div style={{
                            position: "absolute",
                            bottom: 237 / 2 + 36,
                            left: 153 / 2 - 14,
                            height: 25,
                            width: 25,
                            backgroundColor: "grey",
                            borderRadius: "50%",
                            display: "inline-block"
                        }}/>
                        <div
                            style={{position: "absolute", bottom: 237 / 2 + 10, left: 60 - 14, color: "white",}}>OFFLINE
                        </div>
                        <div style={{
                            position: "absolute",
                            bottom: 237 / 2 - 20,
                            left: 44 - 14,
                            color: "white",
                            backgroundColor: "black",
                            borderRadius: 3,
                            wordSpacing: 1
                        }}>&nbsp;{this.state.loginCode}&nbsp;</div>
                        <div style={
                            {
                                fontSize: 13,
                                position: "absolute",
                                bottom: 237 / 2 - 46,
                                left: 60 - 25,
                                color: "white",
                            }
                        }>LOGIN CODE</div>
                    </>}
            </Col>
        </>)
    }
}


class AnimationStrategy {
    constructor() {
        this.stratagies = [];
        this.add(0, 70, 1500);
        this.add(71, 90, 1200);
        this.add(91, 110, 1000);
        this.add(111, 126, 800);
        this.add(127, 1000, 700);
    }

    add(from, to, time) {
        this.stratagies.push({from: from, to: to, time: time});
    }

    get(bpm) {
        for (var i = 0; i < this.stratagies.length; i++) {
            var s = this.stratagies[i];
            if (bpm >= s.from && bpm <= s.to) {
                return s.time;
            }
        }
        return null;
    }

}

export const DefaultAnimationStrategy = new AnimationStrategy();




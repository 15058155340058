import {Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {errorMessage, ProcessingOverlay, StromnoColors, UnexpectedErrorAlert} from "../../common/system";
import Recaptcha from "react-recaptcha";
import Button from "react-bootstrap/Button";
import {StromnoClient} from "../../api/StromnoClient";

export function ResetPassword({onProfileChange}) {

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(undefined);
    const [captcha, setCaptcha] = useState("");
    const [captchaError, setCaptchaError] = useState(undefined);
    const [processing, setProcessing] = useState(false)
    const [success, setSuccess] = useState(false);

    useEffect(() => setEmailError(undefined), [email]);
    useEffect(() => setCaptchaError(undefined), [captcha]);

    if (success) {
        return <Row className="justify-content-md-center ml-2" style={{alignItems: "center", height: '70vh'}}>
            <Col>
                <Row className="justify-content-md-center ml-2">
                    <h3>Link to reset a password was sent to {email}</h3>
                </Row>
                <Row className="justify-content-md-center ml-2">
                    <h4>Contact us via <a href={"https://bit.ly/3nOkWcK"}>Discord</a> or stromno.app@gmail.com</h4>
                </Row>
            </Col>
        </Row>
    }

    return <Row className="justify-content-md-center ml-2" style={{alignItems: "center", height: '70vh'}}>
        <Form>
            <ProcessingOverlay processing={processing}>
                <Row className="justify-content-md-center">
                    <h2>Request reset password</h2>
                </Row>
                <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control value={email} onChange={e => setEmail(e.target.value)} type="email"
                                  placeholder="Enter email"/>
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>
                {errorMessage(emailError)}

                <Recaptcha
                    sitekey="6LdaQiAUAAAAALG9RMtaIcaUcF7zxraNXsOrtnOq"
                    verifyCallback={setCaptcha}
                    expiredCallback={() => setCaptcha("")}
                />

                {errorMessage(captchaError)}


                <Row className={"justify-content-md-center mb-2"}>


                    <Button style={StromnoColors}
                            onClick={async () => {
                                if (validateForm()) {
                                    setProcessing(true)
                                    try {
                                        let [_, error] = await StromnoClient.initiateResetPassword(email, captcha)
                                        setProcessing(false);
                                        setSuccess(true);
                                        if (error) {
                                            setCaptcha("")
                                            UnexpectedErrorAlert();
                                        }
                                    } catch (e) {
                                        console.log(e);
                                        UnexpectedErrorAlert();
                                    }

                                }
                            }}
                    >
                        Request reset
                    </Button>
                </Row>
            </ProcessingOverlay>
        </Form>
    </Row>;

    function validateForm() {
        let error = false;
        if (email === "" || !email.includes("@")) {
            setEmailError("Not valid email");
            error = true;
        }

        if (captcha === "") {
            setCaptchaError("Check captcha");
            error = true
        }
        return !error;
    }
}

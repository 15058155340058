import {Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {SetToken, StromnoClient} from "../../api/StromnoClient";
import Alert from "react-bootstrap/Alert";
import {
    DashboardLink,
    errorMessage,
    LoginPageLink,
    ProcessingOverlay,
    ResetPageLink, StromnoColors,
    UnexpectedErrorAlert
} from "../../common/system";
import Recaptcha from "react-recaptcha";

export function Registration({onProfileChange}) {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(undefined);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(undefined);
    const [passwordAgain, setPasswordAgain] = useState("");
    const [passwordAgainError, setPasswordAgainError] = useState(undefined);
    const [channel, setChannel] = useState("");
    const [captcha, setCaptcha] = useState("");
    const [captchaError, setCaptchaError] = useState(undefined);
    const [profileAlreadyExistsError, setProfileAlreadyExistsError] = useState(false);
    const [_, setCaptchaCounter] = useState(0);
    const [processing, setProcessing] = useState(false)

    useEffect(() => setEmailError(undefined), [email]);
    useEffect(() => setPasswordError(undefined), [password]);
    useEffect(() => setPasswordAgainError(undefined), [passwordAgain]);
    useEffect(() => setCaptchaError(undefined), [captcha]);

    console.log("onProfileChange", onProfileChange);


    return <Row className="justify-content-md-center ml-2" style={{alignItems: "center", height: '70vh'}}>
        <Form>
            <ProcessingOverlay processing={processing}>
                <Row className="justify-content-md-center">
                    <h2>Create an account</h2>
                </Row>
                <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control value={email} onChange={e => setEmail(e.target.value)} type="email"
                                  placeholder="Enter email"/>
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>
                {errorMessage(emailError)}


                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control value={password} onChange={e => setPassword(e.target.value)} type="password"
                                  placeholder="Password"/>
                </Form.Group>

                {errorMessage(passwordError)}

                <Form.Group>
                    <Form.Label>Password Again</Form.Label>
                    <Form.Control value={passwordAgain} onChange={e => setPasswordAgain(e.target.value)} type="password"
                                  placeholder="Password again"/>
                </Form.Group>
                {errorMessage(passwordAgainError)}

                <Form.Group>
                    <Form.Label>Channel</Form.Label>
                    <Form.Control value={channel} onChange={e => setChannel(e.target.value)}
                                  placeholder="Channel"/>
                </Form.Group>


                <Recaptcha
                    sitekey="6LdaQiAUAAAAALG9RMtaIcaUcF7zxraNXsOrtnOq"
                    verifyCallback={setCaptcha}
                    expiredCallback={() => setCaptcha("")}
                />

                {errorMessage(captchaError)}

                {
                    profileAlreadyExistsError &&
                    <Alert variant={"danger"} className={"justify-content-md-center"} style={{textAlign: "center"}}>
                        This email is already in use.
                        <br/>
                        You can login <LoginPageLink>here.</LoginPageLink>
                        <br/>
                        You can reset your password <ResetPageLink>here.</ResetPageLink>
                    </Alert>
                }


                <Row className={"justify-content-md-center mb-2"}>


                    <Button style={StromnoColors}
                            onClick={async () => {
                                if (validateForm()) {
                                    setProcessing(true)
                                    try {

                                        let [result, error] = await StromnoClient.register(email, password, captcha)
                                        setProcessing(false)
                                        if (error) {
                                            if (error && error.data && error.data.reason === "ProfileAlreadyExists") {
                                                setProfileAlreadyExistsError(true)
                                            }
                                            setCaptchaCounter(captcha + 1)
                                            setCaptcha("")
                                            return;
                                        }
                                        SetToken(result.token);
                                        onProfileChange(DashboardLink)
                                    } catch (e) {
                                        console.log(e);
                                        UnexpectedErrorAlert();
                                    }

                                }
                            }}
                    >
                        Register
                    </Button>
                </Row>
                <Row className={"justify-content-md-center mb-2"}>
                    <ResetPageLink>Forgot password?</ResetPageLink>
                </Row>
            </ProcessingOverlay>
        </Form>
    </Row>;

    function validateForm() {
        let error = false;
        if (email === "" || !email.includes("@")) {
            setEmailError("Not valid email");
            error = true;
        }

        if (password === "") {
            setPasswordError("Password should not be empty");
            error = true;
        } else if (password.length < 5) {
            setPasswordError(<>Password should have <br/>at least 5 characters</>)
            error = true;
        }

        if (password !== passwordAgain) {
            setPasswordAgainError("Passwords should be the same")
            error = true;
        }

        if (captcha === "") {
            setCaptchaError("Check captcha");
            error = true
        }
        return !error;
    }
}
import * as React from "react";
import {StromnoClient} from "../../api/StromnoClient";
import Row from "react-bootstrap/Row";
import {Col, Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {WidgetConfiguration} from "./WidgetConfiguration";
import {PulsoidColors, StromnoColors} from "../../common/system";
import {AddHeartRateObs, LearnMoreConnetToPulsoid, LoginAppleWatch} from "../../common/feature-description";
import {useEffect, useState} from "react";
import {copyTextToClipboard} from "../../common/copy-utils";
import {msToTime} from "../../common/time-utils";
import {Status} from "./Status";

export const Dashboard = ({profile}) => {

    const [statistics, setStatistics] = useState();
    const [widget, setWidget] = useState();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        Promise.all([
            StromnoClient.getWidgets(),
            StromnoClient.getStatistics()
        ]).then(values => {
            let [[widgets], [statistics]] = values

            setWidget(widgets[0]);
            setStatistics(statistics);

        })
    }, [])

    useEffect(() => {
        if (copied) {
            let internal = setTimeout(() => setCopied(false), 10000)
            return () => {
                console.log("clear interfal for copied")
                clearInterval(internal)
            }
        }
    }, [copied])


    if (widget === undefined || statistics === undefined) {
        return <div>Loading</div>;
    }
    let widgetUrl = "https://app.stromno.com/widget/view/" + widget.id;

    return <Row className="justify-content-md-center mt-5">
        <Col sm={8}>
            <Row>
                <Status listen={profile.endpoints.listen}/>
                <Col>
                    <Row>
                        <Col xs={"auto"}>
                            <h4>Widget link</h4>
                            <span className={"text-muted"}>use this link in your streaming software</span>
                        </Col>
                        <Col>
                        </Col>
                        {
                            <ConnectPulsoidComponent/>
                        }
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control
                                className="mb-2"
                                value={widgetUrl}
                                readOnly
                            />
                        </Col>
                        <Col xs="auto">
                            <Button type="submit"
                                    style={StromnoColors}
                                    onClick={() => {
                                        copyTextToClipboard(widgetUrl)
                                        setCopied(true);
                                    }}>
                                {copied ? "Copied!" : "Copy!"}
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{color: "white"}}>
                        <Col xs="3">
                            <h4>Streaming time</h4>
                            <h4>{msToTime(statistics.count * 1000)} hours</h4>
                        </Col>
                        <Col xs="2">
                            <h4>Maximum</h4>
                            <h4>{statistics.max}</h4>
                        </Col>
                        <Col xs="2">
                            <h4>Average</h4>
                            <h4>{statistics.avg}</h4>
                        </Col>
                        <Col xs="4">
                            <LoginAppleWatch/>
                            <br/>
                            <AddHeartRateObs/>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <WidgetConfiguration widgetId={widget.id}/>
        </Col>
    </Row>;
}

const ConnectPulsoidComponent = () => {
    const [isPulsoidConnected, setIsPulsoidConnected] = useState(false);
    const [joinedStreamsId, setJoinedStreamsId] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        StromnoClient.getJoinedStreams().then(([joined, error]) => {
            if (joined.isConnected) {
                setIsPulsoidConnected(true)
                setJoinedStreamsId(joined.id)
            }
            setLoading(false);
        })

    }, [])


    if (loading) {
        return <Col>Loading...</Col>;
    }

    let pulsoidStatus;
    if (!isPulsoidConnected) {
        pulsoidStatus = <Col xs="auto">
            <form action={"https://pulsoid.net/oauth2/authorize"}>
                <input type={"hidden"} name={"response_type"} value={"code"}/>
                <input type={"hidden"} name={"client_id"} value={"73e1b612-c271-4d42-ab5c-0cb7585100ad"}/>
                <input type={"hidden"} name={"redirect_uri"} value={"https://app.stromno.com/oauth2/pulsoid"}/>
                <input type={"hidden"} name={"scope"} value={"data:heart_rate:write"}/>
                <input type={"hidden"} name={"state"} value={"state"}/>
                <span className={"pr-3"}><LearnMoreConnetToPulsoid/></span>
                <Button type="submit"
                        style={PulsoidColors}
                        >
                    Connect to Pulsoid
                </Button>
            </form>
        </Col>
    } else {
        pulsoidStatus = <Col xs="auto">
            <Button type="submit"
                    style={PulsoidColors}
                    onClick={async () => {
                        await StromnoClient.disconnectJoinedStreams(joinedStreamsId)
                        setIsPulsoidConnected(false)
                        setJoinedStreamsId("")
                    }}>
                Disconnect from Pulsoid
            </Button>
        </Col>
    }


    return <>
        {pulsoidStatus}
    </>
}
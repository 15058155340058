import * as React from "react";
import {Button, Card} from "react-bootstrap";
import {GreyButtonColors, StromnoColors, WhiteText} from "../../../common/system";
import {Row, Col} from "react-bootstrap";
import "react-toggle/style.css"
import {Form} from "react-bootstrap";
import ColorPicker from "../../../common/ColorPicker";
import FontPicker from "../../../common/FontPicker";
import {StromnoWidget} from "../StromnoWidget";


export class BPMConfiguration extends React.Component {

    state = {
        fontColor: "white"
    }

    render() {

        return <Card style={{...StromnoColors}}>
            <Card.Header style={{backgroundColor: "#343434"}}>
                <Row>
                    <Col className={"justify-content-md-start align-items-center d-flex"} md={"auto"}>
                        <WhiteText><h5>BPM configuration</h5></WhiteText>
                    </Col>
                    <Col className={"d-flex justify-content-end"}>
                        <StromnoWidget pulse={135} configuration={{bpmConfiguration: this.props.configuration}}/>
                    </Col>
                </Row>
            </Card.Header>

            <Card.Body>
                <Form.Group as={Row}>
                    <Form.Label column sm="5" className={"d-flex align-items-center"}><WhiteText>Color</WhiteText></Form.Label>
                    <Col md="5">
                        <ColorPicker color={this.props.configuration.fontColor}
                                     onChangeComplete={(fontColor) => {
                                         this.props.onConfigurationChange({...this.props.configuration, fontColor})
                                     }}/>
                    </Col>

                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm="5" className={"d-flex align-items-center"}><WhiteText>Border
                        Color</WhiteText></Form.Label>
                    <Col sm="auto">
                        <ColorPicker md="auto" color={this.props.configuration.textShadow ? this.props.configuration.textShadow : ""}
                                     onChangeComplete={(textShadow) => {
                                         this.props.onConfigurationChange({...this.props.configuration, textShadow})
                                     }}/>
                    </Col>
                    <Col md={"auto"} className={"d-flex align-items-center"}>
                        <Button onClick={() => this.props.onConfigurationChange({
                            ...this.props.configuration,
                            textShadow: undefined
                        })} style={GreyButtonColors}>Reset</Button>
                    </Col>
                </Form.Group>
                <FontPicker font={this.props.configuration.font} onChange={(font) => this.props.onConfigurationChange({
                    ...this.props.configuration,
                    font
                })}/>
            </Card.Body>

        </Card>;
    }
}


import {Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {DashboardLink, errorMessage, ProcessingOverlay, StromnoColors, UnexpectedErrorAlert} from "../../common/system";
import Recaptcha from "react-recaptcha";
import Button from "react-bootstrap/Button";
import {SetToken, StromnoClient} from "../../api/StromnoClient";

export function ConfirmResetPassword({onProfileChange}) {
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(undefined);

    const [captcha, setCaptcha] = useState("");
    const [captchaError, setCaptchaError] = useState(undefined);
    const [processing, setProcessing] = useState(false)

    useEffect(() => setPasswordError(undefined), [password]);
    useEffect(() => setCaptchaError(undefined), [captcha]);

    return <Row className="justify-content-md-center ml-2" style={{alignItems: "center", height: '70vh'}}>
        <Form>
            <ProcessingOverlay processing={processing}>
                <Row className="justify-content-md-center">
                    <h2>Change your password</h2>
                </Row>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control value={password} onChange={e => setPassword(e.target.value)} type="password"
                                  placeholder="Password"/>
                </Form.Group>

                {errorMessage(passwordError)}


                <Recaptcha
                    sitekey="6LdaQiAUAAAAALG9RMtaIcaUcF7zxraNXsOrtnOq"
                    verifyCallback={setCaptcha}
                    expiredCallback={() => setCaptcha("")}
                />

                {errorMessage(captchaError)}


                <Row className={"justify-content-md-center mb-2"}>


                    <Button style={StromnoColors}
                            onClick={async () => {
                                if (validateForm()) {
                                    setProcessing(true)
                                    try {
                                        let [result, error] = await StromnoClient.confirmResetPassword(
                                            window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
                                            , password, captcha)
                                        setProcessing(false)
                                        if (error) {
                                            UnexpectedErrorAlert();
                                            setCaptcha("")
                                            return;
                                        }
                                        SetToken(result.token);
                                        onProfileChange(DashboardLink)
                                    } catch (e) {
                                        console.log(e);
                                        UnexpectedErrorAlert();
                                    }

                                }
                            }}
                    >
                        Confirm
                    </Button>
                </Row>
            </ProcessingOverlay>
        </Form>
    </Row>;

    function validateForm() {
        let error = false;

        if (password === "") {
            setPasswordError("Password should not be empty");
            error = true;
        } else if (password.length < 5) {
            setPasswordError(<>Password should have <br/>at least 5 characters</>)
            error = true;
        }

        if (captcha === "") {
            setCaptchaError("Check captcha");
            error = true
        }
        return !error;
    }
}

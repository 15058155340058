import {StromnoClient} from "../../api/StromnoClient";
import * as React from "react";
import Websocket from "react-websocket";
import {StromnoWidget} from "../dashboard/StromnoWidget";
import {Container} from "react-bootstrap";

const parseUrl = require("parse-url");

export class WidgetContainer extends React.Component {

    constructor(props) {
        super(props);
        this.client = StromnoClient;
        let url = parseUrl(window.location.href);
        let path = url.pathname.split("/");
        this.state = {
            widgetId: path.pop() || path.pop(),
            isFromLaunch: window.location.href.indexOf("fromLaunch") > -1,
            isTest: url.query.test === "true"
        };
    }

    componentDidMount() {
        this.client.getPublicWidget(this.state.widgetId).then(([s,_]) => {
            this.setState({
                wsListen: this.state.isTest ? "wss://ramiel.pulsoid.net/listen/7f20184a-5fcf-11e6-8b77-86f30ca893d3" : s.ramielUrl,
                configuration: s.configuration,
                metaWidgetId: s.metaId
            })
        })
    }

    handleData = (pulse) => {

        let data = JSON.parse(pulse).data;
        if(data.heartRate === 0){
            return
        }
        this.setState({heartRate: data.heartRate})
    };



    render() {

        let widget;
        if (this.state.metaWidgetId) {
            widget = <div className={"fill-window"} id={"view_widget_container"} style={{backgroundColor: "transparent"}}>
                {
                    this.state.wsListen && <Websocket reconnectIntervalInMilliSeconds={3000}
                                                      url={this.state.wsListen}
                                                      onMessage={this.handleData.bind(this)}/>
                }
                {
                    this.state.heartRate && <StromnoWidget pulse={this.state.heartRate}
                                                           configuration={this.state.configuration}
                                                           fromLaunch={this.state.isFromLaunch}/>
                }
            </div>;
        } else {
            widget = <div/>
        }

        return <Container fluid>{widget}</Container>;
    }


}
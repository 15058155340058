import {v4 as uuidv4} from 'uuid';
import Cookies from 'js-cookie'
//'https://api.stromno.com';//
const BASE_URL = process.env.NODE_ENV === 'development' ? "http://localhost:9000" : 'https://api.stromno.com'
const TOKEN_COOKIE = "PLAY_SESSION"

export const SetToken = (token) => Cookies.set(TOKEN_COOKIE, token, {expires: 365, secure: true})
export const GetToken = () => Cookies.get(TOKEN_COOKIE)
export const CleanToken = () => Cookies.remove(TOKEN_COOKIE)

window.GetToken = GetToken
class StromnoClientInternal {

    options = (method, params) => {
        return {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
            'Content-Type': 'application/json',
                'Authorization': `Bearer ` + GetToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
            redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
            method: method,
            jsonrpc: "2.0",
            params: params,
            id: uuidv4()
        }) // body data type must match "Content-Type" hea
        }
    }


    invokeRpc = (method, params) => fetch(BASE_URL + "/v1/api/rpc", this.options(method, params)).then(res => res.json()).then(res => [res.result, res.error])
    invokePublicRpc = (method, params) => fetch(BASE_URL + "/v1/api/public/rpc", this.options(method, params)).then(res => res.json()).then(res => [res.result, res.error])


    getProfile = () => this.invokeRpc("getUserInfo", {})

    getWidgets = () => this.invokeRpc("getAllWidgets", {})
    getStatistics = () => this.invokeRpc("getStatistics", {})
    generateLoginCode = () => this.invokeRpc("generateLoginCode", {})
    updateWidget = (widgetId, configuration) => this.invokeRpc("updateWidget", {widgetId, configuration})
    getPublicWidget = (widgetId) => this.invokePublicRpc("getWidget", {widgetId})
    getWidget = (widgetId) => this.invokeRpc("getWidget", {widgetId})
    getFeatureDescription = (id) => this.invokeRpc("getFeatureDescription", {id})

    joinStreams = (toToken) => this.invokeRpc("joinStreams",{toToken})
    getJoinedStreams = () => this.invokeRpc("getJoinedStreams",{})
    getPulsoidToken = (code) => this.invokeRpc("getPulsoidToken",{code})
    disconnectJoinedStreams = (id) => this.invokeRpc("disconnectJoinedStreams",{id})

    login = (email, password, captcha) => this.invokePublicRpc("login", {email, password, captcha})
    register = (email, password, captcha) => this.invokePublicRpc("register", {email, password, captcha})
    initiateResetPassword = (email, captcha) => this.invokePublicRpc("initiateResetPassword", {email, captcha})
    confirmResetPassword = (resetId, password, captcha) => this.invokePublicRpc("confirmResetPassword", {resetId, password, captcha})


}

export const StromnoClient = new StromnoClientInternal()
import {Link} from "react-router-dom";
import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import Alert from "react-bootstrap/Alert";

export const ResetPageLink = ({children}) => <Link to={"/reset"}>{children}</Link>
export const LoginPageLink = ({children}) => <Link to={"/login"}>{children}</Link>
export const RegistrationPageLink = ({children}) => <Link to={"/registration"}>{children}</Link>
export const DashboardLink = "/dashboard"

export const ProcessingOverlay = ({processing, children}) => <LoadingOverlay active={processing} text={"Processing your request..."} spinner>{children}</LoadingOverlay>
export const UnexpectedErrorAlert = () =>  alert("Unexpected error! Please, reload page or try again later.")

export function errorMessage(error) {
    return error &&
        <Alert variant={"warning"} className={"justify-content-md-center"} style={{textAlign: "center"}}>
            {error}
        </Alert>
}

export const StromnoMainColor = "#c5b01b"
export const StromnoColors = {backgroundColor: "#414141", borderColor: "#b39d2a", color: StromnoMainColor}
export const PulsoidColors = {backgroundColor: "#414141", borderColor: "#11a2b1", color: "#11a2b1"}
export const WhiteTextColor = "#DFE6E6"
export const GreyTextColor = "#7F8F8F"
export const GreyButtonColors = {backgroundColor: "#414141", borderColor: "#7F8F8F", color: "#7F8F8F"}

export const WhiteText = ({children}) => <span style={{color: WhiteTextColor}}>{children}</span>
export const GreyText = ({children}) => <span style={{color: GreyTextColor}}>{children}</span>
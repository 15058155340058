import React, {useEffect, useState} from 'react';
import './App.css';
import {Container} from "react-bootstrap";
import {Login} from "./components/profile/Login";
import {Registration} from "./components/profile/Registration";
import {Route, Switch} from "react-router";
import {Link, Redirect} from "react-router-dom";
import {Dashboard} from "./components/dashboard/Dashboard";
import {ResetPassword} from "./components/profile/ResetPassword";
import {Auth} from "./common/auth";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import {StromnoColors, StromnoMainColor} from "./common/system";
import Button from "react-bootstrap/Button";
import {CleanToken, StromnoClient} from "./api/StromnoClient";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from "react-notifications";
import {WidgetContainer} from "./components/widget/WidgetContainer";
import {useHistory} from "react-router";
import {ConfirmResetPassword} from "./components/profile/ConfirmResetPassword";
import {ConnectingPulsoid} from "./components/profile/ConnectingPulsoid";


function App() {

    const history = useHistory();

    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        StromnoClient.getProfile().then(([profile, error]) => {
            setProfile(profile)
            setLoading(false)
        })
    }, [])

    function populateProfile(redirect) {
        return StromnoClient.getProfile().then(([profile, error]) => {
            setProfile(profile)
            if (redirect) {
                history.push(redirect)
            }
        })
    }

    if (loading) {
        return <div>Loading...</div>
    }


    let HeaderProfile;

    if (profile) {
        HeaderProfile = <>
            <Col xs={"auto"}>
                <div style={{
                    color: StromnoMainColor,
                    fontSize: 17,
                    paddingBottom: 4
                }}>{profile.email}</div>
            </Col>
            <Col xs={"auto"}>
                <a className="btn btn-success" style={StromnoColors}
                   target={"_blank"}
                   rel="noopener noreferrer"
                   href={"https://www.stromno.com/faq"}>FAQ</a>
            </Col>
            <Col xs={"auto"}>
                <Button style={StromnoColors} block onClick={() => {
                    CleanToken();
                    setProfile(undefined);
                }}>Log out</Button>
            </Col>
        </>
    } else {
        HeaderProfile = <>
            <Col xs={"auto"}>
                <Link className="btn btn-success" style={StromnoColors}
                      to={"/registration"}>Create an account</Link>
            </Col>
            <Col xs={"auto"}>
                <Link className="btn btn-success" style={StromnoColors}
                      to={"/login"}>Login</Link>
            </Col>
        </>
    }

    const MainPart = ({children}) => {
        return <div style={{backgroundColor: "#414141", height: "100%"}}>
            <Container fluid style={{backgroundColor: "#414141", color: StromnoMainColor}}>
                <Row className="justify-content-md-center">
                    <Col sm={8}>
                        <Row style={{alignItems: "end"}}>
                            <Col xs={"auto"} onClick={() => window.location = "https://stromno.com"}
                                 className={"pointer"}>
                                <Image
                                    src={"https://pulsoid-magi.nyc3.digitaloceanspaces.com/stromno/predefined/stromno-logo-heart.png"}
                                    style={{width: 70}}/>
                            </Col>
                            <Col style={{color: StromnoMainColor}} xs={"auto"}
                                 onClick={() => window.location = "https://stromno.com"}
                                 className={"pointer"}>
                                <h2>Stromno</h2>
                            </Col>

                            <Col/>
                            {HeaderProfile}
                        </Row>
                    </Col>
                </Row>
                {children}
                <NotificationContainer/>
            </Container>
        </div>
    }

    return (


        <Switch>
            <Route path="/login">
                <MainPart><Login onProfileChange={populateProfile}/></MainPart>
            </Route>

            <Route path="/reset/:id">
                <MainPart><ConfirmResetPassword onProfileChange={populateProfile}/></MainPart>
            </Route>
            <Route path="/oauth2/pulsoid">
                <MainPart>
                    <ConnectingPulsoid/>
                </MainPart>
            </Route>
            <Route path="/reset">
                <MainPart><ResetPassword/></MainPart>
            </Route>
            <Route path="/registration">
                <MainPart><Registration onProfileChange={populateProfile}/></MainPart>
            </Route>
            <Route path="/dashboard">
                <MainPart>
                    <Auth profile={profile}>
                        <Dashboard/>
                    </Auth>
                </MainPart>
            </Route>

            <Route path="/widget/view/:id">
                <WidgetContainer/>
            </Route>

            <Redirect to="/dashboard"/>

        </Switch>
    );


}

export default App;

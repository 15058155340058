import {Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {SetToken, StromnoClient} from "../../api/StromnoClient";
import Alert from "react-bootstrap/Alert";
import {
    errorMessage,
    ProcessingOverlay,
    RegistrationPageLink,
    ResetPageLink, StromnoColors,
    UnexpectedErrorAlert
} from "../../common/system";

import Recaptcha from "react-recaptcha"

export function Login({onProfileChange}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [captcha, setCaptcha] = useState("")
    const [profileNotFoundError, setProfileNotFoundError] = useState(false)
    const [processing, setProcessing] = useState(false);
    const [emailError, setEmailError] = useState(undefined);
    const [captchaError, setCaptchaError] = useState(undefined);
    const [passwordError, setPasswordError] = useState(undefined);


    useEffect(() => setEmailError(undefined), [email]);
    useEffect(() => setPasswordError(undefined), [password]);
    useEffect(() => setCaptchaError(undefined), [captcha]);


    return <Row className="justify-content-md-center ml-2" style={{alignItems: "center", height: '70vh'}}>
        <Form>
            <ProcessingOverlay processing={processing}>
                <Row className="justify-content-md-center">
                    <h2>Login</h2>
                </Row>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control value={email} onChange={e => setEmail(e.target.value)} type="email"
                                  placeholder="Enter email"/>
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                {errorMessage(emailError)}

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control value={password} onChange={e => setPassword(e.target.value)} type="password"
                                  placeholder="Password"/>
                </Form.Group>
                {errorMessage(passwordError)}
                <Recaptcha
                    sitekey="6LdaQiAUAAAAALG9RMtaIcaUcF7zxraNXsOrtnOq"
                    verifyCallback={setCaptcha}
                    expiredCallback={() => setCaptcha("")}
                />

                {/*<ReCAPTCHA sitekey={"6LdaQiAUAAAAALG9RMtaIcaUcF7zxraNXsOrtnOq"}*/}
                {/*           onChange={setCaptcha}*/}
                {/*           onExpired={() => setCaptcha("")}*/}
                {/*           className={"mb-2"}/>*/}

                {errorMessage(captchaError)}

                {
                    profileNotFoundError && <Alert variant={"warning"} className={"justify-content-md-center"}
                                                   style={{textAlign: "center"}}>
                        There is no such combination of <br/>email an password.
                        <br/>
                        You can register new profile <RegistrationPageLink>here.</RegistrationPageLink>
                        <br/>
                        You can reset password <ResetPageLink>here.</ResetPageLink>
                    </Alert>
                }

                <Row className={"justify-content-md-center mb-2"}>
                    <Button variant="primary"
                            style={StromnoColors}
                            onClick={async () => {
                                if (validForm()) {
                                    setProcessing(true)
                                    try {
                                        let [result, error] = await StromnoClient.login(email, password, captcha)
                                        setProcessing(false)
                                        if (error) {
                                            if (error.data.reason === "ProfileNotFound") {
                                                setProfileNotFoundError(true)
                                            }
                                            return;
                                        }
                                        SetToken(result.token);
                                        onProfileChange("/dashboard")
                                    } catch (e) {
                                        console.log(e);
                                        UnexpectedErrorAlert();
                                    }
                                }
                            }}
                    >
                        Login
                    </Button>
                </Row>
                <Row className={"justify-content-md-center mb-2"}>
                    <ResetPageLink>Forgot password?</ResetPageLink>
                </Row>
                <Row className={"justify-content-md-center mb-2"}>
                    <RegistrationPageLink>Don't have an account?</RegistrationPageLink>
                </Row>
            </ProcessingOverlay>
        </Form>

    </Row>;


    function validForm() {
        let error = false;
        if (email === "" || !email.includes("@")) {
            setEmailError("Not valid email");
            error = true;
        }

        if (captcha === "") {
            setCaptchaError("Check captcha")
            error = true;
        }


        if (password === "") {
            setPasswordError(<>Password should not <br/> be empty captcha</>)
            error = true;
        }


        return !error;
    }

}
import * as React from "react";
import Row from "react-bootstrap/Row";
import {Col, Form} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import {ProcessingOverlay, StromnoColors, StromnoMainColor} from "../../common/system";
import {useEffect, useState} from "react";
import {StromnoWidget} from "./StromnoWidget";
import {StromnoClient} from "../../api/StromnoClient";
import {NotificationManager} from 'react-notifications';
import {ImageConfiguration} from "./settings/ImageConfiguration";
import {BPMConfiguration} from "./settings/BPMConfiguration";

const LogoPictureVariant = 1;
const HeartPictureVariant = 2;
const NonePictureVariant = 3;

const AntonBlackBpmVariant = 1;
const OxygenRedBpmVariant = 2;
const ArialBlackBpmVariant = 3;


const ImagesVariants = {
    1: "https://pulsoid-magi.nyc3.digitaloceanspaces.com/stromno/predefined/stromno-logo-heart.png",
    2: "https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/stromno/predefined/red-heart-shape.png",
}

const BpmVariants = {
    1: {
        color: "white",
        font: "Anton"
    },
    2: {
        color: "red",
        font: "Oxygen"
    },
    3: {
        color: "black",
        font: "Staatliches",
        shadowSize: 2,
        shadowColor: "white"
    }
}

const currentConfig = (selectedBpmVariant, selectedImageVariant) => {
    return {
        ...BpmVariants[selectedBpmVariant],
        ...(ImagesVariants[selectedImageVariant] ? {imageUrl: ImagesVariants[selectedImageVariant]} : {})
    }
}

export const WidgetConfiguration = ({widgetId}) => {
    return <NewWidgetConfiguration widgetId={widgetId}/>
    // if (window.location.href.includes("test=true")) {
    //     return <NewWidgetConfiguration widgetId={widgetId}/>
    // } else {
    //     return <OldWidgetConfiguration widgetId={widgetId}/>
    // }
}

export const OldWidgetConfiguration = ({widgetId}) => {
    const [counter, setCounter] = useState(60)
    const [selectedImageVariant, setSelectedImageVariant] = useState(1)
    const [selectedBpmVariant, setSelectedBpmVariant] = useState(1)
    const [processing, setProcessing] = useState(true)

    useEffect(() => {
        StromnoClient.getWidget(widgetId).then(([widget, error]) => {
            switch (widget.configuration.imageUrl) {
                case ImagesVariants[LogoPictureVariant]:
                    setSelectedImageVariant(LogoPictureVariant)
                    break;
                case ImagesVariants[HeartPictureVariant]:
                    setSelectedImageVariant(HeartPictureVariant)
                    break;
                default:
                    setSelectedImageVariant(NonePictureVariant)
            }

            switch (widget.configuration.font) {
                case BpmVariants[AntonBlackBpmVariant].font:
                    setSelectedBpmVariant(AntonBlackBpmVariant);
                    break;
                case BpmVariants[OxygenRedBpmVariant].font:
                    setSelectedBpmVariant(OxygenRedBpmVariant);
                    break;
                case BpmVariants[ArialBlackBpmVariant].font:
                    setSelectedBpmVariant(ArialBlackBpmVariant);
                    break;
                default:
                    setSelectedBpmVariant(AntonBlackBpmVariant);
            }
            setProcessing(false)

        })

        let intervalId = setInterval(() => {
            setCounter(counter => (counter + 1) % 60 + 60)
        }, 600);

        return () => {
            clearInterval(intervalId);
        };
    }, [widgetId])


    return <ProcessingOverlay processing={processing}>
        <Row className={"mt-5"}>

            <link href="https://fonts.googleapis.com/css2?family=Anton&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css2?family=Oxygen&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css2?family=Staatliches&display=swap" rel="stylesheet"/>
            <Col sm={6}>
                <Row className={"justify-content-md-center"}>
                    <h4 style={{color: StromnoMainColor}}>Settings</h4>
                </Row>
                <Row>
                    <Col>
                        <Row className="justify-content-md-center">
                            <Image
                                src={"https://pulsoid-magi.nyc3.digitaloceanspaces.com/stromno/predefined/stromno-logo-heart.png"}
                                style={{width: 100}}/>

                        </Row>
                        <Row className="justify-content-md-center">
                            <Form.Check
                                onChange={() => setSelectedImageVariant(LogoPictureVariant)}
                                checked={selectedImageVariant === LogoPictureVariant}
                                type={"radio"}
                            />
                        </Row>
                    </Col>

                    <Col>
                        <Row className="justify-content-md-center">
                            <Image
                                src={"https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/stromno/predefined/red-heart-shape.png"}
                                style={{width: 100}}/>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Form.Check
                                onChange={() => setSelectedImageVariant(HeartPictureVariant)}
                                checked={selectedImageVariant === HeartPictureVariant}
                                type={"radio"}
                            />
                        </Row>
                    </Col>

                    <Col>
                        <Row className="justify-content-md-center" style={{height: 100, alignItems: "center"}}>
                            <h4>none</h4>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Form.Check
                                onChange={() => setSelectedImageVariant(NonePictureVariant)}
                                checked={selectedImageVariant === NonePictureVariant}
                                type={"radio"}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row className="justify-content-md-center">
                            <StromnoWidget pulse={counter} configuration={BpmVariants[AntonBlackBpmVariant]}/>

                        </Row>
                        <Row className="justify-content-md-center">
                            <Form.Check
                                onChange={() => setSelectedBpmVariant(AntonBlackBpmVariant)}
                                checked={selectedBpmVariant === AntonBlackBpmVariant}
                                type={"radio"}
                            />
                        </Row>
                    </Col>
                    <Col>
                        <Row className="justify-content-md-center">

                            <StromnoWidget pulse={counter} configuration={BpmVariants[OxygenRedBpmVariant]}/>

                        </Row>
                        <Row className="justify-content-md-center">
                            <Form.Check
                                onChange={() => setSelectedBpmVariant(OxygenRedBpmVariant)}
                                checked={selectedBpmVariant === OxygenRedBpmVariant}
                                type={"radio"}
                            />
                        </Row>
                    </Col>
                    <Col>
                        <Row className="justify-content-md-center">
                            <StromnoWidget pulse={counter} configuration={BpmVariants[ArialBlackBpmVariant]}/>

                        </Row>
                        <Row className="justify-content-md-center">
                            <Form.Check
                                onChange={() => setSelectedBpmVariant(ArialBlackBpmVariant)}
                                checked={selectedBpmVariant === ArialBlackBpmVariant}
                                type={"radio"}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-md-center mt-5">
                    <Col xs={"auto"}>
                        <Button style={StromnoColors} className={"pr-5 pl-5"} onClick={async () => {
                            setProcessing(true)
                            try {
                                await StromnoClient.updateWidget(widgetId, currentConfig(selectedBpmVariant, selectedImageVariant))
                                NotificationManager.info('Widget configuration has been updated.', "", 2000);
                            } catch (e) {

                            }
                            setProcessing(false);
                        }}>Save</Button>
                    </Col>
                </Row>
            </Col>
            <Col sm={6}>
                <Row className={"justify-content-md-center"}>
                    <h4 style={{color: StromnoMainColor}}>Preview</h4>
                </Row>
                <Row className={"justify-content-md-center"} style={{height: "70%", alignItems: "center"}}>
                    <StromnoWidget pulse={counter}
                                   configuration={currentConfig(selectedBpmVariant, selectedImageVariant)}/>
                </Row>
            </Col>
        </Row>
    </ProcessingOverlay>

}

export const NewWidgetConfiguration = ({widgetId}) => {
    const [counter, setCounter] = useState(60)
    const [processing, setProcessing] = useState(true)

    const [imageConfiguration, setImageConfiguration] = useState({});
    const [bpmConfiguration, setBpmConfiguration] = useState({});
    const [originalConfiguration, setOriginalConfiguration] = useState({});

    useEffect(() => {
        StromnoClient.getWidget(widgetId).then(([widget, error]) => {
            setImageConfiguration(widget.configuration.imageConfiguration ? widget.configuration.imageConfiguration :  {
                "animated": true,
                "imageColor": "#c5b01b",
                "key": "PredefinedEcgKey"
            })
            setBpmConfiguration(widget.configuration.bpmConfiguration ? widget.configuration.bpmConfiguration : {
                "font": "Anton",
                "fontColor": "#ffffffff",
                "textShadow": "#343434ff"
            })
            setOriginalConfiguration(widget.configuration)
            setProcessing(false)

        })

        let intervalId = setInterval(() => {
            setCounter(counter => (counter + 1) % 80 + 80)
        }, 600);

        return () => {
            clearInterval(intervalId);
        };
    }, [widgetId])


    return <ProcessingOverlay processing={processing}>
        <Row className={"mt-5"}>

            <link href="https://fonts.googleapis.com/css2?family=Anton&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css2?family=Oxygen&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css2?family=Staatliches&display=swap" rel="stylesheet"/>
            <Col sm={6}>
                <Row className={"justify-content-md-center"}>
                    <h2 style={{color: StromnoMainColor}}>Settings</h2>
                </Row>
                <ImageConfiguration configuration={imageConfiguration} onConfigurationChange={setImageConfiguration}/>
                <div className={"p-2"}/>
                <BPMConfiguration configuration={bpmConfiguration} onConfigurationChange={setBpmConfiguration}/>
                <Row className="justify-content-md-center mt-5">
                    <Col xs={"auto"}>
                        <Button style={StromnoColors} className={"pr-5 pl-5"} onClick={async () => {
                            setProcessing(true)
                            try {
                                await StromnoClient.updateWidget(widgetId, {
                                    imageConfiguration,
                                    bpmConfiguration
                                })
                                NotificationManager.info('Widget configuration has been updated.', "", 2000);
                            } catch (e) {

                            }
                            setProcessing(false);
                        }}>Save</Button>
                    </Col>
                </Row>
            </Col>
            <Col sm={6}>
                <Row className={"justify-content-md-center"}>
                    <h2 style={{color: StromnoMainColor}}>Preview</h2>
                </Row>
                <Row className={"justify-content-md-center"} style={{height: "70%", alignItems: "center"}}>
                    <StromnoWidget pulse={counter}
                                   configuration={{...originalConfiguration, imageConfiguration, bpmConfiguration}}/>
                </Row>
            </Col>
        </Row>
        <div className={"p-5"}></div>
    </ProcessingOverlay>


}




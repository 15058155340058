import * as React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {default as FFontPicker} from "font-picker-react";
import {Col} from "react-bootstrap";
import {WhiteText} from "./system";


export default class FontPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            font: props.font
        }
    }

    static getDerivedStateFromProps(props, state){
        if(props.font && state.font == null){
            return {
                font: props.font
            };
        } else {
            return null;
        }
    }

    render() {
        if(this.state.font) {
            return <Form.Group as={Row}>
                <link
                    href={`https://fonts.googleapis.com/css?family=${this.state.font}`}
                    rel={"stylesheet"}/>
                <Form.Label column sm="5">
                    <WhiteText>Font</WhiteText>
                </Form.Label>

                <Col sm={"2"} style={{color: "black"}}>
                    <FFontPicker apiKey={"AIzaSyAlV1ZeFbk96XTQ_mXLvzbaMMTPK5cH7Cs"}
                                 activeFontFamily={this.state.font}
                                 limit={100}
                                 categories={["display", "handwriting"]}
                                 onChange={font => this.setState({font: font.family}, () => this.props.onChange(font.family))}
                    />
                </Col>

            </Form.Group>;
        } else {
            return <></>;
        }
    }

}
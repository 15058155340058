import * as React from "react";
import {InputGroup} from "react-bootstrap";
import {SketchPicker} from "react-color";


export default class ColorPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {showColorPicker: false}
    }

    render() {
        return <>
            <InputGroup onClick={() => this.setState({showColorPicker: !this.state.showColorPicker})} style={{cursor: "pointer", userSelect: "none"}}>
                <InputGroup.Prepend>
                    <InputGroup.Prepend>
                        <InputGroup.Text style={{
                            color: this.props.color,
                            backgroundColor: this.props.color
                        }}>&nbsp;&nbsp;&nbsp;</InputGroup.Text>
                    </InputGroup.Prepend>
                </InputGroup.Prepend>
                {/*<Form.Control readOnly value={this.props.color}/>*/}
            </InputGroup>

            {
                this.state.showColorPicker &&
                <SketchPicker
                    onClose={() => this.setState({showColorPicker: false})}
                    color={this.props.color}
                    onChangeComplete={(color) => {
                        if (this.props.disableOpacity) {
                            this.props.onChangeComplete(color.hex);
                        } else {
                            if (color.rgb.a === 0) {
                                this.props.onChangeComplete("#00000000")
                            } else {

                                this.props.onChangeComplete(color.hex + Math.floor(color.rgb.a * 255).toString(16))
                            }

                        }
                    }}
                    onChange={(color) => {
                        if (this.props.disableOpacity) {
                            this.props.onChangeComplete(color.hex);
                        } else {
                            if (color.rgb.a === 0) {
                                this.props.onChangeComplete("#00000000")
                            } else {

                                this.props.onChangeComplete(color.hex + Math.floor(color.rgb.a * 255).toString(16))
                            }

                        }

                    }}
                />
            }

        </>
    }


}
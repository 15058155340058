import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import '../../App.css';
import {PredefinedImageConfig} from "./settings/ImageConfiguration";
import '../../widget.css'

export const StromnoWidget = ({pulse, configuration}) => {

    if (window.location.href.includes("fa0e79f5")) {
        return <NewStromnoWidget pulse={pulse} configuration={configuration}/>
    }
    if (configuration.imageConfiguration || configuration.bpmConfiguration) {
        return <NewStromnoWidget pulse={pulse} configuration={configuration}/>
    } else {
        return <OldStromnoWidget pulse={pulse} configuration={configuration}/>
    }
}

export const NewStromnoWidget = ({pulse, configuration}) => {
    const {imageConfiguration, bpmConfiguration, colorMode} = configuration;

    let bpmComponent = <></>;
    if (bpmConfiguration) {
        const {font, fontColor, textShadow} = bpmConfiguration;
        let fontStyle = {};
        if (font) {
            fontStyle = {fontFamily: "'" + font + "'"};
        }
        const calculatedTextShadow = textShadow ? {textShadow: `2px 0 0 ${textShadow}, -2px 0 0 ${textShadow}, 0 2px 0 ${textShadow}, 0 -2px 0 ${textShadow}, 1px 1px ${textShadow}, -1px -1px 0 ${textShadow}, 1px -1px 0 ${textShadow}, -1px 1px 0 ${textShadow}`} : {};
        bpmComponent = <>
            <link href={`https://fonts.googleapis.com/css?family=${font}`} rel={"stylesheet"}/>
            <span id="widget-bpm" style={{
                fontSize: 100,
                color: fontColor,
                ...calculatedTextShadow,
                ...fontStyle
            }}>{pulse}</span>
        </>

    }

    let imageComponent = <></>;
    if (imageConfiguration && imageConfiguration.key) {
        imageComponent = <div className={"stacked-image-parent"} id="widget-image"
                              style={{height: 150, width: 150}}>
            {PredefinedImageConfig[imageConfiguration.key].svg(
            imageConfiguration.imageColor,
            imageConfiguration.animated,
            pulse, imageConfiguration.colorMode)}
        </div>
    }


    return <div style={{backgroundColor: "transparent"}} className={"fill-window"} id={"heartRate"}>
        <Row style={{alignItems: "center"}}>
            <Col xs={"auto"}>

                {imageComponent}

            </Col>
            <Col xs={"auto"}>
                {bpmComponent}
            </Col>
        </Row>
    </div>
}

export const OldStromnoWidget = ({pulse, configuration}) => {
    // eslint-disable-next-line no-unused-vars
    const {shadowSize, font, shadowColor, imageUrl, imageUrl2, color} = configuration;

    let image = <></>;
    if (imageUrl) {
        if (imageUrl === "https://pulsoid-magi.nyc3.cdn.digitaloceanspaces.com/stromno/predefined/red-heart-shape.png") {
            image = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100px">
                <path
                    d="M 51.234 87.533 L 49.708 86.261 C 16.9 59.558 8 50.147 8 34.889 C 8 22.172 18.172 12 30.889 12 C 41.316 12 47.164 18.825 51.234 23.402 C 55.303 18.825 61.152 12 71.58 12 C 84.295 12 94.469 22.172 94.469 34.889 C 94.469 50.147 85.567 59.558 52.76 86.261 L 51.234 87.533 Z"
                    stroke="red" fill="none" stroke-width="6"/>
            </svg>;
        } else if (imageUrl === "https://pulsoid-magi.nyc3.digitaloceanspaces.com/stromno/predefined/stromno-logo-heart.png") {
            image = <svg xmlns="http://www.w3.org/2000/svg" width="160px" viewBox="-10 -10 500 500">
                <path
                    d="M 170 300 L 250 400 L 450 150 L 350 50 L 250 150 L 150 50 L 50 150 L 130 250 L 190 250 L 210 170 L 235 300 L 250 250 L 290 250"
                    stroke="#cab418" stroke-width="13" stroke-linejoin="round" stroke-linecap="round"
                    fill="none"/>
            </svg>;
        } else {
            image = <img className={"stacked-image-1"} src={imageUrl} alt={"Loading..."}
                         style={{width: 100}}/>
        }
    }

    return <div style={{
        backgroundColor: "transparent",
        fontFamily: "'" + font + "'",
        color: color,
        textShadow: shadowSize ? "2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff" : undefined
    }} className={"fill-window"} id={"heartRate"}>

        <link href={`https://fonts.googleapis.com/css?family=${font}`} rel={"stylesheet"}/>
        <Row style={{alignItems: "center"}}>
            <Col xs={"auto"}>
                <div className={"stacked-image-parent"} id="widget-image">
                    {image}
                    {imageUrl2 &&
                    <img id="widget-image-2" className={"stacked-image-2"} src={imageUrl2} alt={"Loading..."}
                         style={{width: 100}}/>}
                </div>
            </Col>
            <Col xs={"auto"}>
                <span id="widget-bpm" style={{fontSize: 100}}>{pulse}</span>
            </Col>
        </Row>
    </div>
}